// src/pages/zktecoTransactionsPage.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Pagination,
  Alert, // Added for error handling
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import axios from 'axios';

const ZKTecoTransactionsPage = () => {
  // State variables
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null); // Date object
  const [endDate, setEndDate] = useState(null); // Date object
  const [error, setError] = useState(null); // Error state

  // Fetch transactions from API
  const fetchTransactions = async (
    page = 1,
    pageSize = 10,
    startDate = null,
    endDate = null
  ) => {
    setLoading(true);
    setError(null); // Reset error state before fetching
    try {
      // Prepare query parameters
      const params = {
        page,
        pageSize,
      };
      if (startDate) {
        params.startDate = startDate.toISOString();
      }
      if (endDate) {
        params.endDate = endDate.toISOString();
      }

      const response = await axios.get(
        'https://satitchula.app/api/integration/zkteco/transactions',
        { params }
      );

      setTransactions(response.data.transactions);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setError('Failed to fetch transactions. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle filter
  const handleFilter = () => {
    // Reset to first page when applying new filters
    fetchTransactions(1, pagination.pageSize, startDate, endDate);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    fetchTransactions(value, pagination.pageSize, startDate, endDate);
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'zk_user_id', headerName: 'User ID', width: 100 },
    { field: 'upn', headerName: 'UPN', width: 200 },
    { field: 'device', headerName: 'Device', width: 150 },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 200,
      // Format the timestamp for better readability
      valueFormatter: (params) =>
        new Date(params.value).toLocaleString(),
    },
    { field: 'sn', headerName: 'Surname', width: 150 },
    { field: 'givenName', headerName: 'Given Name', width: 150 },
    { field: 'department', headerName: 'Department', width: 100 },
    // Add more fields as needed
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        ZKTeco Transactions
      </Typography>

      {/* Error Alert */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Filter Section */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilter}
          >
            Apply Filters
          </Button>
        </Box>
      </LocalizationProvider>

      {/* DataGrid Section */}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={transactions}
          columns={columns}
          pageSize={pagination.pageSize}
          rowsPerPageOptions={[pagination.pageSize]}
          autoHeight
          disableSelectionOnClick
          getRowId={(row) => row.id}
        />
      )}

      {/* Pagination Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default ZKTecoTransactionsPage;
